import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import AuthService from '../../../api/services/AuthService';
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';

const UserComissionLevels = () => {
    const [commissionLevels, setcommissionLevels] = useState([]);
    const [editableData, setEditableData] = useState({ LEVEL_1: "", LEVEL_2: "", LEVEL_3: "", LEVEL_4: "", LEVEL_5: "", LEVEL_6: "", LEVEL_7: "", LEVEL_8: "", LEVEL_9: "", LEVEL_10: "" });
    const [id, setid] = useState();
    useEffect(() => {
        getCommisionLevels()
    }, []);

    const EditLevelsDetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.updateLevels(id,editableData).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage('Levels Updated')
                getCommisionLevels()
                try {
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const getCommisionLevels = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.userCommissionLevels().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setcommissionLevels([result?.data?.Levels])
                setid(result?.data?._id)
                try {
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const LevelUpdate = (item) => {
        setEditableData(item)
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEditableData({ ...editableData, [name]: +value })
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i class="fas fa-money-bill-wave"></i></div>
                                        User Comission Levels
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10 ">
                    <div className='row g-4 justify-content-center' >
                        {commissionLevels ? commissionLevels?.map((item) => {
                            return (
                                <div className='col-md-8' >
                                    <div className="card mb-4 h-100">
                                        <div class="card-body d-flex justify-content-center flex-column p-3 m-3 ">
                                            <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                <h5 class="mb-0">Comission Details</h5>
                                            </div>

                                            <hr class="mt-0" />
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 1</strong>
                                                    <span>{item?.LEVEL_1} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 2</strong>
                                                    <span>{item?.LEVEL_2} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 3</strong>
                                                    <span>{item?.LEVEL_3} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 4</strong>
                                                    <span>{item?.LEVEL_4} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 5</strong>
                                                    <span>{item?.LEVEL_5} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 6</strong>
                                                    <span>{item?.LEVEL_6} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 7</strong>
                                                    <span>{item?.LEVEL_7} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 8</strong>
                                                    <span>{item?.LEVEL_8} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 9</strong>
                                                    <span>{item?.LEVEL_9} %</span></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Level 10</strong>
                                                    <span>{item?.LEVEL_10} %</span></div>
                                            </div>
                                            <form>
                                                <div class="row mt-2"><div class="col"><button class="btn btn-success btn-block w-100 mt-2" data-bs-toggle="modal" data-bs-target="#bank_modal" type="button" onClick={() => LevelUpdate(item)}>Edit</button></div></div></form></div>

                                    </div>
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>

                {/* ******Comission Modal******** */}
                <div class="modal" id="bank_modal" tabindex="-1" aria-hidden="true" ><div class="modal-dialog  alert_modal" role="document"><div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="exampleModalCenterTitle">Edit Comission</h5><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">
                    <form >
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 1</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_1' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_1} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 2</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_2' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_2} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 3</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_3' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_3} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 4</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_4' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_4} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 5</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_5' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_5} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 6</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_6' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_6} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 7</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_7' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_7} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 8</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_8' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_8} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 9</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_9' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_9} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Level 10</label>
                            <input className="form-control form-control-solid" type="number" name='LEVEL_10' onWheel={(e) => e.target.blur()} onChange={handleInput} value={editableData?.LEVEL_10} />
                        </div>

                        <button className="btn btn-large btn-primary bn-block w-100 mt-3 btn_admin" data-bs-dismiss="modal" aria-label="Close" type="button" onClick={EditLevelsDetails} > Update Levels</button>

                    </form>

                </div></div></div></div>




            </main>
        </div>
    )
}


export default UserComissionLevels
